
body {
  font-family: 'Poppins', sans-serif ;
}
.status-badge {
  height: 20px;
  width: 20px;
  border: 2px solid whitesmoke;
  border-radius: 50%;
  margin: 0 auto;
}

.success {
  background-color: green;
}

.danger {
  background-color: red;
}
.license-waiting{
background-color: orange;
}
.license-expires{
background-color: yellow;
}
.parent-container {
  max-height: 85vh;
  width: 95%;
  margin: 0 auto;
  /* border:1px solid black; */
}

.child-container {
  width: 100% !important;
  background-color: white;
  border-radius: 14px;
  /* border:1px solid purple; */
  display: flex;
 
}

@media (max-width:600px){
  .child-container{
    min-height: 10dvh;
    width:90%;
    display: flex;
  }
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.alignItemCenter {
  align-items: center;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyCententEnd {
  justify-content: end;
}

.justifyCententStart {
  justify-content: flex-start;
}

.flex-1 {
  flex: 1;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-around-between {
  justify-content: space-around;
}

.main-color {
  color: var(--primary-color);
}

.left {
  width: 70%;
  height: 100%;
  /* border-right: 1px solid black; */
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  background-color: white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  border-radius: 20px;
}

.right {
  width: 30%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.margin-left-5 {
  margin-left: 5px;
}
.margin{
  margin:30px auto;
}
#SvgjsText4244,
#SvgjsText4126 {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
}

.MuiDialogTitle-root {
  font-family: 'Poppins', sans-serif !important;
}

.background-red {
  background-color: red;
}

.background-blue {
  background-color: blue;
}

.customInput input::-webkit-input-placeholder {
  font-size: 15px !important;
  color: red !important;
}

.weather-card{
  height:50vh;
  width:20vw;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.mini-card{
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items:center ;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}