.Closebtnweather button{
    border: none;
    background: none;
    margin: 10px;
}
.weatherlogo{
    display: flex;
    align-items: center;
    justify-content: center;

}
.weatherlogo img{
    width: 294px;
    height: 184px;
}
.nameinput{
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.nameinput input{
    width: 360px;
    height: 40px;
    border: 1px solid #EEE9E9;
    border-radius: 8px;
}
.nameinput select{
    width: 360px;
    height: 45px;
    border: 1px solid #EEE9E9;
    border-radius: 8px;
    outline: none;
    color: #848484;
}
.nameinput label{
color: #848484;
}

.inputs{
    display: flex;
    width: 45vw;
    flex-wrap: wrap;
    margin: 32px;
}
