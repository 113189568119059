/* #checkbox---custom {
  -webkit-appearance: none;
  width: 40px;
  height: 25px;
  background-color: white;
  border: 1px solid #016afe;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
}
#checkbox---custom::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 30%;
  height: 1.25rem;
  width: 1.25rem;
  background-color: white;
  border: 2px solid #016afe;
  transition: all 0.3s;
}
#checkbox---custom::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);

  height: 0.8rem;
  width: 0.8rem;
  z-index: 100000;
  background-color: #016afe;
  transition: all 0.3s;
}
#checkbox---custom:checked {
  background-color: #016afe;
}
#checkbox---custom:checked::after,
#checkbox---custom:checked::before {
  left: 70%;
} */
.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  .custom-checkbox input {
    opacity: 0;
    position: absolute;
  }
  
  .custom-checkbox .checkmark {
    width: 20px;
    height: 20px;
    background-color: white;
    border: 2px solid #1e90ff;
    display: inline-block;
    border-radius: 3px;
    transition: background-color 0.2s;
    position: relative;
  }
  
  .custom-checkbox input:checked + .checkmark {
    background-color: #1e90ff;
  }
  
  .custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }
  
  .custom-checkbox input:checked + .checkmark::after {
    display: block;
  }
  