/* src/Modal.css */


  
  .modal-content {
overflow: auto;
    z-index: 1;
    background: white;
    padding: 20px;
    border-radius: 5px;
    height: 72vh;
    width: 20vw;
    margin-top: 19vh;
    margin-left: 73.5vw;
    background: '#f0f0f0',
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  