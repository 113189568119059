.sidebar-container {
    min-height: 100vh;
    max-width: 100vw;
    border-right: 5px solid #d6d2f7;
    /* opacity: 0.1; */
    display: flex;
    flex-direction: column;
    
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.sidebar-container .sidebar-upper-sec {
    height: 23vh;
    margin-top: 18px;
    width: 100%;
    display: flex;
   

    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.topsec{
    background: #313842;
    display: flex;
   height: 183px;
   width: 224px;
   border-top-left-radius: 10px; /* Adjust the value as needed */
border-top-right-radius: 10px;
   justify-content: center;
    align-items: center;
}
.topsec img {
    height:58px;
    width:159px;
    padding: 4px;
   
    object-fit: cover;
}

.sidebar-container .sidebar-middle-sec {
    height: 74vh;
    width: 100%;
    display: flex;
    /* border:1px solid green; */
}

.side-bar-box {
    height: 98%;
    width: 95%;
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
@media only screen and (min-width: 1367px) and (min-height: 769px){
    .side-bar-box {
        height: 98%;
        width: 95%;
        margin: 8px auto;
       
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        
    }
}

.side-bar-box .links {
    height: 50px;
    width: 200px;
    margin: 1px auto;
    /* border:1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    /* background: #F3F0FF; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 32px;
    font-family: 'Poppins', sans-serif;
   gap: 12px;
    /* z-index: 100000000000000000000000 !important; */
}

/* .side-bar-box .links:active {
    background: red !important; 
} */



.links a:hover{
    background-color: transparent;
}

/* .side-bar-box .links a:active {
    background-color: #349eff !important;
}

.side-bar-box .links a:visited {
    background-color: #349eff !important;
} */

/* .side-bar-box .links a:hover{
    background-color: #349eff;
    color: white;
    border-radius: 15px;
    margin-right: 30px;
    width: 150px;
} */



.btn-btn-primery{
    background-color: var(--primary-color);
    border: none;
    border-radius:8px ;
    height: 50px;
    width:160px;
    color: white;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.bell{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:15px;
    margin-right: 15px;

}
.bell img{
    height: 85px;
}

.links a {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: -0.02em;
    color: #B8B8B8;
}

.link-a-color {
    color:rgba(0,0,0,0.2);
    margin: 0 10px;
}
.link-active-a-color{
    color:var(--primary-color);
    margin:0 10px;
}
.sidebar-container .sidebar-bottom-sec {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* border:1px solid yellow; */
}

.sidebar-bottom-sec .settings {
    height: 80px;
    width: 80px;
    background: var(--primary-color);
    border-radius: 0px 18px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

a.active {
    background-color: #1144ff;
}