#checkbox--custom{
    -webkit-appearance: none;
    width:40px;
    height:25px;
    border-radius:20px;
    background-color: white;
    border:1px solid #016AFE;
    outline: none;
    cursor: pointer;
    transition: all .3s;
    position: relative;
}
#checkbox--custom::after{
    content: '';
    position:absolute;
    top:50%;
    left:30%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    height:1.25rem;
    width:1.25rem;
    background-color: white;
    border:2px solid #016AFE;
    transition: all .3s;
}
#checkbox--custom::before{
    content: '';
    position:absolute;
    top:50%;
    left:30%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    height:.8rem;
    width:.8rem;
    z-index: 100000;
    background-color: #016AFE;
    transition: all .3s;
}
#checkbox--custom:checked{
    background-color: #016AFE;
}
#checkbox--custom:checked::after,#checkbox--custom:checked::before{
    left:70%;
}