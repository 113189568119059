.editor-container{
    min-height: 100dvh;
    max-width: 100dvw;
    /* border:5px solid black; */
    display: flex;
    flex-direction: column;
}
.editor-header{
    height:5dvh;
    width:100dvw;
    /* border:1px solid green; */
    display: flex;
}
.header-left{
    height:100%;
    width:25%;
    /* border:1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.header-right{
    height:100%;
    width:75%;
    /* border:1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.editor-main-body{
    height: calc(100dvh - 5dvh);
    width: 100dvw;
    /* border:1px solid red; */
    display: flex;
}
.editor-main-body .editor-side-bar{
    height:100vh;
    width:25dvw;
    /* border:1px solid yellow; */
    display: flex;
}
.editor-side-bar .tabs{
    height:100vh;
    width:5dvw;
    border:1px solid rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
}
.editor-side-bar .tabs-panel{
    height:100vh;
    width:20dvw;
    border-top:1px solid rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
}
.editor-side-bar .tabs .tab-menu{
    height:10dvh;
    width:100%;
    /* border:1px solid black; */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.editor-main-body .editor-body{
    height: 100dvh;
    width:calc(100dvw - 25dvw);
    /* border:6px solid pink; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DDDDDD;
}

.canvas-prop-container{
    width:70%;
    /* border:1px solid black; */
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}
.canva-prop-container .fields-prop{
    height:10vh;
    min-width:20dwh;
    border:1px solid black;
}
.fields-prop input{
    border:1px solid black;
    outline: none;
    height: 30px;
    width: 100px;
}
.fields-prop .canva-size-button{
background-color: white;
border:1px solid black;
text-transform: capitalize;
color:black;
width:100%;

}
.pop-over{
    height:5vh;
    min-width:30dwh;
   /* border:1px solid black; */
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 5px;
}
.pop-over input{
    border:1px solid black;
    outline: none;
    height: 30px;
    width: 100px;
}
.pop-over .canva-confirm-size-button{
    /* background-color: white; */
    /* border:1px solid black; */
    text-transform: capitalize;
    cursor: pointer;
}
