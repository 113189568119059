.tabContainer{
    min-height:78vh;
    max-width:100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border:1px solid black; */
}
@media (max-width:600px){
    .tabContainer{
        min-height: 78vh;
        max-width: 100dvw;
        /* border:1px solid black; */
        /* margin-left: -25px; */
    }
}
.tabHeader{
    min-height: 5vh;
    width:80vw;
    display: flex;
    justify-content:center;
    align-items:center;
    padding: 20px;
}
@media (max-width:600px){
    .tabHeader{
        width:100dvw;
    }
}
.tabHeader .tabs{
    height: 10vh;
    width:40vw;
    margin:0px auto;
    margin-top:-20px;
}
@media (max-width:600px){
    .tabHeader .tabs{
        width:100dvw;
        margin-left: 43px;
        /* margin:-20px -50px; */
        /* margin-left: -35px */
    }
}
.tabBody{
    min-height:55vh;
    width:100%;
    /* border:1px solid black; */
}
.tabBody .innerBody{
max-height:55vh;
width:100%;
margin:0 auto;
overflow-y: auto;
overflow-x: hidden;
/* border:1px solid black; */
}
.innerBody::-webkit-scrollbar{
    scroll-behavior: smooth;
    width:5px;
}
.innerBody::-webkit-scrollbar-track{
   
}
.innerBody::-webkit-scrollbar-thumb{
    width:5px !important;
background-color: var(--primary-color);
}
.tabFooter{
    min-height:10vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tabBtn{
    height:50px;
    width:150px;
    border:none;
    border-bottom:1px solid var(--primary-color);
    outline: none;
    background-color: transparent;
    color:black;
    font-weight: bold;
    font-size: larger;
    border-radius: 2px;
    cursor: pointer;
}
@media (max-width:600px){
    .tabHeader .tabs .tabBtn{
        height:5dvh;
        width:18dvw;
        font-size: small;
    }
}
.tabBtn:last-child{
    border-right:1px solid var(--primary-color);
}
.tabBtn:first-child{
    border-left:1px solid var(--primary-color);
}
.tabActive{
    background: var(--primary-color-rgba);
    border-radius: 6px 6px 0px 6px;
    font-size: white;
}