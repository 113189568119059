.text-component-container{
    height: 100dvh;
    width:100%;
    /* border:1px solid red; */
    display: flex;
    flex-direction: column;
}
.text-fields{
    height:5dvh;
    width:100%;
    margin:10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border:1px solid black; */
}
.add-text-btn{
    width:200px;
}
.text-tool-container{
    height:5dvh;
    width:70%;
    display: flex;
    gap:5px;
}
.text-tool-container .text-tool{
    height:40px;
    width:40px;
    /* border:1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-tool button{
    height:100%;
    width:100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-align-tools{
    height:5vh;
    width:10vw;
    border:1px solid black;
    padding:5px;
    display: flex;
    justify-content: center;
}