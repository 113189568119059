.shape-container{
    /* min-height: 100vh; */
    width:100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin:10px 10px;
}
.shape-box{
    height:6vh;
    width:4vw;
    border:1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.shape-tool-container{
    height:5dvh;
    width:70%;
    display: flex;
    gap:5px;
}
.shape-inside-popover{
    height:10%;
    width:100%;
    /* border:1px solid black; */
    display:flex;
    flex-direction:column;
    gap:5;
    padding:5;
}