.folder{
    cursor:pointer;
    height: 80px;
    width: 150px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    display: block;
    gap: 5px;
    justify-content: center;
    align-items: center;
    background:var(--primary-color);
    color:white;
    transition: all .5s ease-in;
    position: relative;
}
.edit-icon{
  position: absolute;
  top:0;
  right:0;
  z-index: 10;
}
/* .folder:hover{
  box-shadow: 1px 1px 3px 2px rgba(0,0,0,.2),
  -1px -1px 3px 2px rgba(0,0,0,.2);
} */