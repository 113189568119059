.layer-container{
    height: 100dvb;
    width:100%;
    display: flex;
    flex-direction: column;
}
.layers{
    height: 5dvh;
    width:90%;
    margin:10px auto;
    /* border:1px solid black; */
    border-radius: 5px;
    box-shadow: 2px 2px 5 5 rgba(0, 0,0,0.5),
    -2px -2px 5 5 rgba(0, 0,0,0.5)
    ;
    background-color: #D7DADE;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.layers img{
    max-width: 90%;
    max-height: 3dvb;
    margin:0 10px;
}