.dashboard-container {
  min-height: 83vh;
  width: 100%;
  /* border:1px solid black; */
}

.dashboard-left {
  height: 80vh;
  width: 70%;
  /* border:1px solid black; */
}

.dashboard-right {
  height: 80vh;
  width: 30%;
  /* border:1px solid black; */
}

.dashboard-right-upper {
  height: 50vh;
  width: 97%;
  /* border:1px solid black; */
  margin: 10px auto;
  background: #ffffff;
  border-radius: 24px;
}

.dashboard-right-bottom {
  height: 30vh;
  width: 97%;
  margin: 10px auto;
  /* border:1px solid black; */
  background: #ffffff;
  border-radius: 24px;
}

.dashboard-right-bottom .progress-bar {
  height: 90%;
  width: 90%;
  margin: 10px auto;
  /* border:1px solid black; */
}

.progress-bar .progress-bar-title p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #15192c;
  margin: -5px 0;
}

.progress-bar .progress-bar-title {
  /* border:1px solid black; */
  height: 30%;
}

.progress-bar .progress {
  height: 90%;
}

.progress-bar .progress-bar-title span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #92959e;
  margin-top: -20px;
}

.dashboard-right-upper .donut-header {
  height: 20%;
  width: 98%;
  margin: 0 auto;
  /* border-bottom: 1px solid black; */
}

.dashboard-right-upper .donut-body {
  height: 60%;
  width: 98%;
  margin: 0 auto;
  /* border-bottom: 1px solid black; */
}

.donut-body .donut-body-days {
  height: 100%;
  width: 20%;
  /* border:1px solid black; */
}

.donut-body .donut-graph {
  height: 100%;
  width: 80%;
  /* border:3px solid green; */
}

.dashboard-right-upper .donut-footer {
  height: 20%;
  width: 98%;
  margin: 0 auto;
  border-bottom: 1px solid black;
}

.panel-wrapper {
  min-height: 20vh;
  width: 98%;
  margin: 10px auto;
  /* border:1px solid black; */
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  gap: 5px;
}

.panel-wrapper .panel-tab {
  min-height: 15vh;
  width: 190px;
  /* border:1px solid black; */
  margin: 5px auto;
}

.panel-tab .panel-upper {
  height: 70%;
  width: 100%;
  /* border:1px solid black; */
}

.panel-tab .panel-bottom {
  height: 30%;
  width: 100%;
  /* border:1px solid black; */
  justify-content: center;
  align-items: flex-end;
}

.panel-upper .panel-icon .icon {
  height: 63px;
  width: 63px;
  opacity: 0.65;
  border-radius: 10px;
}

.panel-bottom .panel-des {
  height: 40px;
  width: 150px;
  /* background: #FFF2E9; */
  opacity: 0.65;
  border-radius: 10px 10px 0 0;
}

.panel-upper .panel-title .panel-text,
.panel-count {
  margin: 8px 0;
}

.content-back-color {
  background: #fff2e9;
}

.screen-back-color {
  background: #ede8ff;
}

.playlist-back-color {
  background: #eaf9ff;
}

.user-back-color {
  background: #ffebef;
}

/* line graph */
.line-graph-container {
  min-height: 55vh;
  width: 98%;
  margin: 10px auto;
  /* border:1px solid black; */
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  gap: 5px;
}

.apexcharts-toolbar {
  display: none !important;
}
.minLoader {
  height: 10px;
  width: 10px;
  border: 2px solid whitesmoke;
  border-radius: 50%;
  border-top: 2px solid blueviolet;
  animation: loader 1s infinite linear;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.acontrol-area {
  display: flex;
  background-color: white;
  margin: 0px 0px;
  width: 79vw;
  height: 60px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
}

.box1 {
  width: 354px;
  height: 18vh;
  border-radius: 12px;
  border: 1px solid hsla(0, 0%, 96%, 1);
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  font-size: 16px;
  font-weight: 400;
}
.circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  /* background-color: hsla(337, 79%, 61%, 1); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxe2{
    width: 300px;
    height: 18vh;
    border-radius: 12px;
    border: 1px solid hsla(0, 0%, 96%, 1);
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
}
.screentext{
    font-weight: 500;
    font-size: 24px;
}
.mainbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 79vw;
    margin: 7px 0px;
}
.boxes{
    display: flex;
    height: 17.5vh;
    width: 430px;
    background-color: white;
    justify-content: space-between;
    /* align-items: center; */
    border-radius: 12px;
    flex-direction: column;

}
.text{
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin: 15px;

}
.imgscreen{
    /* background: hsla(209, 71%, 94%, 1); */
height: 60px;
width: 60px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
margin: 15px;
}
.text1{
    color: hsla(0, 0%, 30%, 1);
    font-size: 16px;
    font-weight: 500;

}
.numb{
    font-size: 28px;
    font-weight: 700;
}
.wrap{
    display: flex;
    width: 430px;

    background-color: white;
    justify-content: space-between;
    align-items: center;
    
}
.lowertext{
    margin: 16px;
}
@media only screen and (min-width: 1367px) and (min-height: 769px){
  .mainbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 79vw;
    
    margin: 10px 0px;
  }
  .boxe2{
    width: 300px;
    height: 20vh;
    border-radius: 12px;
    border: 1px solid hsla(0, 0%, 96%, 1);
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
}
.box1 {
  width: 354px;
  height: 20vh;
  border-radius: 12px;
  border: 1px solid hsla(0, 0%, 96%, 1);
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  font-size: 16px;
  font-weight: 400;
}
.boxes{
  display: flex;
  height: 20vh;
  width: 430px;
  background-color: white;
  justify-content: space-between;
  /* align-items: center; */
  border-radius: 12px;
  flex-direction: column;

}
.mainbox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 79vw;
  margin: 20px 0px;
}
}
