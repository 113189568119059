.channelmainname {
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  margin-top: 8px;
  width: 17vw;
}
.channelmainnamea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  margin-top: 8px;
  width: 27vw;
}
.channelmainname span {
  border: none;
  color: black;
  background: transparent;
  cursor: pointer;
}
.channelname {
  width: 17dvh;
  display: flex;
  flex-direction: column;
  padding: 20;
  gap: 10;
}
.mainwrap {
  display: flex;
  flex-wrap: wrap;
  width: 25vw;
}
.wrapcheck {
  display: flex;
}
.wrapcheck input {
  transform: scale(1.5);
  width: 15px;
  height: 15px;
  margin-right: 20px;
}
.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
}

.checkbox-input {
  position: absolute;
  opacity: 0; /* Hide the actual checkbox */
  cursor: pointer;
}

.checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid black; /* Custom border color */
  background-color: transparent;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.checkbox-input:checked + .checkmark {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.checkbox-input:checked + .checkmark::after {
  content: "✔"; /* Tick mark */
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  border: 2px solid var(--primary-color);
}
.channelmaintext {
  width: 740px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}
.bellowtext {
  color: hsla(0, 0%, 52%, 1);
  font-size: 12px;
  font-weight: 400;
  width: 740px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.layoutmodelinpput {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10;
  width: 740px;
}
.wraplabelandtext {
  display: flex;
  flex-direction: column;
}
.wraplabelandtext span {
  color: hsla(0, 0%, 0%, 1);
  font-size: 12px;
  font-weight: 400;
}
.inputlayoutmodel {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 17px;
  width: 715px;
}
.inputlayoutmodel input {
  border-radius: 8px;
  border: none;
  width: 300px;
}
.dyanmic-playlist-sel-prev{
  display: flex;

}
.dyanmic-playlist-sel{
  width: 251px;
  height: 96px;
display: flex;
  border-radius: 8px;
 border: 1px solid hsla(0, 13%, 92%, 1);
 margin: 20px;
}

.content{
  font-size: 12px;
  font-weight: 500;

}
.playlist{
  color: hsla(0, 2%, 67%, 1);

font-size: 12px;
font-weight: 500;
}
.none{
  color: var(--primary-color);
}
.contentdyanmic-playlist-sel1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.contentdyanmic-playlist-sel2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.dyanmic-playlist-prev{
  width: 262px;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
 border: 1px solid hsla(0, 13%, 92%, 1);
 margin: 20px;
}
.preview{
  font-size: 12px;
  font-weight: 600;
  margin: 5px 10px;
}
.box{
  height: 51px;
  width: 96px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid var(--primary-color);
}
.main-box{
  display: flex;
  gap: 5px;
}
.box img{
  width: 83px;
  height: 40px;
}
.textandbox{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.textandbox span {
  font-size: 10px;
  font-weight: 500;
  color: hsla(0, 0%, 51%, 1);


}
.cancleandsavebtns{
  display: flex;
  justify-content: flex-end;
  margin: 25px;
  gap: 10px;
}
.successtext{
  display: flex;
  flex-direction: column;
}
.contetsuccess{
  width: 600;
 display: flex;
 align-items: center;
 justify-content: center;
 margin: 36px;
}
.span1{
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 550;
  margin: 10px;
}
.span2{
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;

  color: hsla(0, 0%, 59%, 1);

}
.span3{
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
margin: 20px;
  color: var(--primary-color);

}
/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} */

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  width:  50vh; /* Prevents modal from getting too wide */
  max-height: 90%; /* Prevents modal from getting too tall */
  overflow-y: auto; /* Enables scrolling if content exceeds max height */
}

