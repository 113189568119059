/* src/Modal.css */

.modal-overlay {
    z-index: 100000000000000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {

    z-index: 1000000000000000000;
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-height: 530px;
    min-width: 300px;
    margin-top: 85px;
    margin-left: 1165px;
    /* background: #f0f0f0; */
    overflow-x: hidden;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  