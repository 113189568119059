:root{
  --primary-color:#4DA9FF;
  --primary-color-rgba:rgba(77, 169, 255,0.7);
  --primary-forecolor:#ffffff;
  --secandary-color:#ffffff;
  --primary-text:#7B7777;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* html::-webkit-scrollbar {
  display: none;
  width:5px;
} */

code {
  font-family: 'Open Sans', sans-serif !important;
}

.apexcharts-title-text {
  font-family: 'Open Sans', sans-serif !important;
}

.MuiDialog-paper {
  border-radius: 16px !important;
}

.custom-checkbox-root .MuiSvgIcon-root{
  width: 50px;
  height: 50px;
  fill: #551fff;
}