.add-content-playlist span {
    padding-left: 6px;
    font-size: 14px;
}

.delete-playlist span{
    padding-right: 6px;
    font-size: 14px;
}
.nameofplaylist{
font-family: 'Poppins',sans-serif;
font-weight: 500;
font-size: 18px;
/* line-height: 27px; */
display: flex;
justify-content: space-between;
}
.topSecModel{
    display: flex;
    align-items: center;
   justify-content: space-between;
}
.buttons  button{
    margin:8px ;
}
.wrap{
    display: flex;
}