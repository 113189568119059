.heading h3 {
  margin: 20px 0px;
}
.main {
  margin: 30px;
  width: 800px;
  height: 300px;
  background-color: black;
  border-radius: 12px;
}
.location-name {
  color: white;
  width: 187px;
  height: 37px;
  border-radius: 12px;
  background: #2d2d2d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  gap: 10px;
}
.weathertype {
  color: white;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  margin-left: 20px;
}
.weathercard {
  display: flex;
}
.column1 {
  height: 216px;
  width: 178px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 40px;
}
.column2 {
  height: 216px;
  width: 178px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.column2 img {
  height: 173px;
  width: 173px;

}
.temprature {
  color: #4fa9fe;
  font-size: 52px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cel{
    padding-bottom: 34px;
    font-size: 38px;
}
.column3 {

    height: 216px;
    /* width: 178px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* margin: 0 20px; */
  }
  .date{
    color: white;
    display: flex;
    justify-content: flex-end;
    width: 287px;
    font-size: 16px;
    font-weight: 400;
  }
  .humidity{
    color: white;
    height: 84px;
    width: 196px;
    background-color: #2d2d2d;
    border-radius: 12px;
    display: flex;
  flex-direction: column; 
  align-items: center; 
  text-align: center;
  gap: 2px;
  }
  .humidity img{
    height: 28px;
    width: 28px;
  }
  .mini-card{
    height: 219px;
    width: 104px;
    margin: 10px;
    background-color: #272727;
    border-radius: 24px;
  }
  .minictext{
    border-bottom: 2px solid  #7E7E7E;
  }