.custom-checkbox {
  height: 20px;
  width: 20px;
}
.main--checkbox {
  height: 15px !important;
  width: 15px !important;
  border: 1px solid black;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  margin:0 5px;
}
#main-checkbox:checked + .main--checkbox::after {
  position: absolute;
  content: "\2713";
  height: 15px !important;
  width: 15px !important;
  background-color: var(--primary-color-rgba);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-forecolor);
  border:1px solid var(--primary-color);
  text-align: center;
  line-height: 18px;
  font-weight: 600;
  font-size: 14px;
}
