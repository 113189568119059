.picture-component-container{
    height:100dvh;
    width:100%;
    display: flex;
    justify-content: center;
}
.upslash-container{
    display: flex;
    height:100%;
    width:100%;
    flex-direction: column;
}
.upslash-divs{
    height:150px;
    width:150px;
    border:1px solid black;
}